.not-found {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.not-found__title {
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 140px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 245px 0 5px 0;
}

.not-found__description {
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.not-found__button {
  text-decoration: none;
  color: #4285f4;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 184px 0 60px 0;
  transition: opacity 0.3s linear;
  border: none;
  padding: 0;
  width: max-content;
  align-self: center;
  background: none;
}

.not-found__button:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .not-found__title {
    padding: 407px 0 5px 0;
  }
  .not-found__button {
    margin: 184px 0 222px 0;
  }
}

@media screen and (max-width: 767px) {
  .not-found__title {
    padding: 330px 0 9px 0;
    font-size: 80px;
  }
  .not-found__description {
    font-size: 12px;
  }
  .not-found__button {
    font-size: 12px;
    margin: 284px 0 30px 0;
  }
}
