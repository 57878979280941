.search-form {
  padding: 71px 70px 10px 70px;
}

.search-form__input-container {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border: solid 1.5px rgba(170, 170, 170, 0.2);
  padding: 0px 5px 0px 17px;
}

.search-form__input {
  border: none;
  padding: 0;
  width: 90%;
  background: #202020;
  color: #8b8b8b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  min-height: 69px;
}

.search-form__input::placeholder {
  color: #8b8b8b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.search-form__input:focus {
  outline: none;
  background-color: rgba(170, 170, 170, 0.01);
}

.search-form__button {
  padding: 0;
  width: 100px;
  height: 52px;
  border-radius: 5px;
  background: #4285f4;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  align-self: center;
  transition: opacity 0.3s linear;
}

.search-form__button:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .search-form {
    padding: 71px 30px 10px 30px;
  }
  .search-form__input-container {
    padding: 0px 11px 0px 24px;
  }
}

@media screen and (max-width: 767px) {
  .search-form {
    padding: 71px 14px 10px 14px;
  }
  .search-form__input-container {
    padding: 0px 6px 0px 10px;
  }
  .search-form__button {
    width: 100px;
    height: 44px;
  }
  .search-form__input {
    min-height: 53px;
  }
  .search-form__input::placeholder {
    font-size: 16px;
    line-height: 16px;
  }
}
