.footer {
  background: #202020;
  padding: 133px 70px 10px 70px;
}

.footer__title {
  margin: 0;
  color: #8b8b8b;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: solid 1px #424242;
  min-height: 35px;
}

.footer__container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 10px 0;
}

.footer__copyright {
  margin: 0;
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer__links {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.footer__link {
  text-decoration: none;
  color: #fff;
  text-align: right;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: opacity 0.3s linear;
}

.footer__link:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .footer {
    padding: 98px 30px 10px 30px;
  }
  .footer__title {
    min-height: 37px;
  }
  .footer__container {
    padding: 18px 0 10px 0;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    padding: 53px 14px 10px 14px;
  }
  .footer__title {
    font-size: 12px;
  }
  .footer__container {
    padding: 28px 0 10px 0;
    flex-direction: column-reverse;
    align-items: center;
  }
  .footer__link {
    font-size: 12px;
  }
  .footer__links {
    flex-direction: column;
    gap: 9px;
    padding: 0;
    text-align: center;
  }
  .footer__copyright {
    padding: 28px 0 0 0;
    font-size: 12px;
  }
}
