.header {
  width: 100%;
  background: #202020;
  display: flex;
  justify-content: space-between;
  min-height: 74px;
  align-items: center;
}

.header_theme_blue {
  background: #073042;
}

.header__logo {
  padding: 0 0 0 70px;
  transition: opacity 0.3s linear;
}

.header__logo:hover {
  opacity: 0.7;
  cursor: pointer;
}

.header__logo_type_form {
  padding: 0;
}

@media screen and (max-width: 1023px) {
  .header__logo {
    padding: 0 0 0 30px;
  }

  .header__logo_type_form {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .header__logo {
    padding: 0 0 0 14px;
  }
}
