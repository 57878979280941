.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.form__header {
  width: 396px;
  padding: 54px 0 19px 0;
}

.form__container {
  display: flex;
  flex-direction: column;
  max-width: 396px;
}

.form__title {
  margin: 0;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 0 42px 0;
}

.form__button {
  border: none;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 396px;
  height: 45px;
  border-radius: 3px;
  background: #4285f4;
  margin: 125px 0 0 0;
  transition: opacity 0.3s linear;
}

.form__button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.form__button_type_login {
  margin: 195px 0 0 0;
}

.form__button_disabled {
  background: #f8f8f8;
  color: #c2c2c2;
}

.form__link-container {
  padding: 16px 2px 70px 0px;
  display: flex;
  gap: 7px;
}

.form__text {
  color: #8b8b8b;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form__link {
  text-decoration: none;
  color: #4285f4;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: opacity 0.3s linear;
}

.form__link:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .form__header {
    width: 396px;
    padding: 216px 0 19px 0;
  }
  .form__link-container {
    padding: 16px 2px 230px 0px;
  }
}
@media screen and (max-width: 767px) {
  .form__header {
    width: 120px;
    padding: 39px 0px 31px 54px;
  }
  .form__container {
    max-width: 260px;
  }
  .form__title {
    text-align: center;
    padding: 0 0 81px 0;
  }
  .form__button {
    font-size: 12px;
    width: 260px;
    margin: 213px 0 0 0;
  }
  .form__link-container {
    padding: 14px 2px 30px 0px;
  }
  .form__text {
    font-size: 12px;
  }
  .form__link {
    font-size: 12px;
  }
  .form__button_type_login {
    margin: 283px 0 0 0;
  }
}
