.movies {
  display: flex;
  flex-direction: column;
}
.movies__error {
  min-height: 17px;
  color: #ff004c;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
