.App {
  background: #202020;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  font-family: 'Inter', Arial, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}
