.saved-movies {
  display: flex;
  flex-direction: column;
}

.saved-movies__empty {
  padding: 169px 0 0 0;
}

@media screen and (max-width: 1023px) {
  .saved-movies__empty {
    padding: 140px 0 0 0;
  }
}

@media screen and (max-width: 767px) {
  .saved-movies__empty {
    padding: 185px 0 0 0;
  }
}
