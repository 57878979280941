.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s linear;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translateX(+100%);
}
.mobile-menu_opened {
  transform: translateX(0);
}

.mobile-menu__container {
  width: 520px;
  height: 1024px;
  position: absolute;
  top: 0;
  right: 0;
  background: #202020;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-menu__button {
  border: none;
  padding: 0;
  background-image: url(../../images/mobile-close-button.svg);
  background-position: center;
  background-color: #202020;
  width: 22px;
  height: 22px;
  align-self: end;
  margin: 27px 27px 0 0;
  transition: opacity 0.3s linear;
}

.mobile-menu__button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.mobile-menu__links {
  display: flex;
  flex-direction: column;
  padding: 110px 0 0 0;
  gap: 23px;
}

.mobile-menu__link {
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: opacity 0.3s linear;
  border-bottom: 2px rgba(255, 255, 255, 0) solid;
  padding: 0 0 4px 0;
}

.mobile-menu__link:hover {
  opacity: 0.7;
  cursor: pointer;
}

.mobile-menu__link_type_checked {
  border-bottom: 2px #fff solid;
  width: 75px;
  align-self: center;
}

.mobile-menu__link_type_width {
  width: 200px;
}

.mobile-menu__box {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 50px;
  padding: 592px 0 0 50px;
  transition: opacity 0.3s linear;
}

.mobile-menu__box:hover {
  opacity: 0.7;
  cursor: pointer;
}

.mobile-menu__account {
  margin: 0;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.mobile-menu__icon {
  background-image: url(../../images/icon__account.svg);
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background-color: #313131;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 767px) {
  .mobile-menu__container {
    width: 320px;
    height: 100vh;
  }
  .mobile-menu__button {
    margin: 18px 18px 0 0;
  }
  .mobile-menu__links {
    padding: 104px 0 0 0;
    gap: 17px;
  }
  .mobile-menu__link {
    padding: 0 0 6px 0;
  }
  .mobile-menu__link_type_checked {
    width: 74px;
  }

  .mobile-menu__link_type_width {
    width: 200px;
  }
  .mobile-menu__box {
    padding: 420px 0 0 50px;
  }
}
