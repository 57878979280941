.form__label {
  color: #8b8b8b;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 0 4px 0;
}

.form__input {
  border: none;
  padding: 0;
  background: #202020;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #424242;
  outline: 0;
  padding: 0 0 9px 0;
}

.form__input::placeholder {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form__input:focus {
  outline: 0;
  border-bottom: 1px solid #4285f4;
}

.form__error {
  color: #ee3465;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-height: 24px;
  margin-top: 3px;
}
