.promo {
  background-color: #073042;
  padding: 75px 70px 10px 70px;
  display: flex;
  justify-content: space-between;
}

.promo__title {
  margin: 0;
  color: #fff;
  font-size: 50px;
  font-weight: 400;
  line-height: 116%;
  width: 640px;
}

.promo__subtitle {
  margin: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
  padding-top: 36px;
  max-width: 310px;
}

.promo__logo {
  background-image: url(../../images/landing-logo.svg);
  background-repeat: no-repeat;
  width: 310px;
  height: 304px;
}

.promo__link {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.33;
  padding: 0;
  text-decoration: none;
  width: 129px;
  height: 36px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s linear;
  margin: 146px 0 62px 0;
}

.promo__link:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .promo {
    flex-direction: column-reverse;
    align-items: center;
  }
  .promo__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .promo__logo {
    margin: 0 0 48px 0;
  }
}

@media screen and (max-width: 1023px) {
  .promo__title {
    font-size: 40px;
    line-height: 125%;
  }
  .promo__logo {
    margin: 66px 0 47px 0;
  }
  .promo__subtitle {
    padding-top: 22px;
    max-width: 260px;
  }
  .promo__link {
    margin: 91px 0 65px 0;
  }
}

@media screen and (max-width: 767px) {
  .promo {
    padding: 4px 55px 10px 55px;
  }
  .promo__logo {
    width: 210px;
    height: 206px;
    background-size: cover;
    margin: 66px 0 45px 0;
  }
  .promo__title {
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -1.2px;
    max-width: 300px;
  }
  .promo__link {
    margin: 80px 0 20px 0;
  }
}
