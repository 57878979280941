.header__unauthorized-links {
  padding: 0 70px 0 0;
  display: flex;
  gap: 30px;
}

.header__unauthorized-link {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.3s linear;
}

.header__unauthorized-link:hover {
  opacity: 0.7;
  cursor: pointer;
}

.header__unauthorized-link_type_signin {
  width: 76px;
  min-height: 32px;
  border-radius: 3px;
  background: #3ddc84;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}

.header__unauthorized-link_type_signup {
  align-self: center;
}

@media screen and (max-width: 1023px) {
  .header__unauthorized-links {
    padding: 0 30px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .header__unauthorized-links {
    padding: 0 14px 0 0;
    gap: 14px;
  }
  .header__unauthorized-link {
    font-size: 10px;
  }
  .header__unauthorized-link_type_signin {
    width: 54px;
    min-height: 26px;
  }
}
