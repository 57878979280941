.search-form__filter-checkbox {
  display: inline-block;
  position: relative;
  margin-top: 26px;
}

.search-form__filter-checkbox-input {
  height: 0;
  width: 0;
  opacity: 0;
}

.search-form__filter-checkbox-checkbox {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 36px;
  height: 20px;
  border-radius: 20px;
  background-color: #ebebeb;

  transition: 0.3s;
}

.search-form__filter-checkbox-checkbox:hover {
  opacity: 0.7;
  cursor: pointer;
}

.search-form__filter-checkbox-checkbox::before {
  content: '';
  position: absolute;
  cursor: pointer;
  left: 7px;
  bottom: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  transition: 0.3s;
}

.search-form__filter-checkbox-input:checked
  + .search-form__filter-checkbox-checkbox {
  background: #3ddc84;
}

.search-form__filter-checkbox-input:checked
  + .search-form__filter-checkbox-checkbox::before {
  transform: translateX(14.5px);
}

.search-form__filter-checkbox-text {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: 46px;
  transition: opacity 0.3s linear;
}

.search-form__filter-checkbox-text:hover {
  opacity: 0.7;
  cursor: pointer;
}

.search-form__filter-checkbox-line {
  border-bottom: solid 1px rgba(170, 170, 170, 0.2);
  width: 100%;
  display: flex;
  padding: 44px 0 0 0;
}

@media screen and (max-width: 1023px) {
  .search-form__filter-checkbox-line {
    padding: 52px 0 0 0;
  }
}

@media screen and (max-width: 767px) {
  .filter-checkbox {
    margin-top: 30px;
  }
  .filter-checkbox__text {
    font-size: 11px;
  }
  .filter-checkbox__line {
    padding: 64px 0 0 0;
  }
}

/* .search-form__filter-checkbox {
  display: inline-block;
  position: relative;
  margin-top: 26px;
}

.search-form__filter-checkbox-input {
  height: 0;
  width: 0;
  opacity: 0;
}

.search-form__filter-checkbox-checkbox {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 36px;
  height: 20px;
  border-radius: 20px;
  background: #3ddc84;
  transition: 0.3s;
}

.search-form__filter-checkbox-checkbox:hover {
  opacity: 0.7;
  cursor: pointer;
}

.search-form__filter-checkbox-checkbox::before {
  content: '';
  position: absolute;
  cursor: pointer;
  right: 7px;
  bottom: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  transition: 0.3s;
}

.search-form__filter-checkbox-input:checked
  + .search-form__filter-checkbox-checkbox {
  background-color: #ebebeb;
}

.search-form__filter-checkbox-input:checked
  + .search-form__filter-checkbox-checkbox::before {
  transform: translateX(-14.5px);
}

.search-form__filter-checkbox-text {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: 46px;
  transition: opacity 0.3s linear;
}

.search-form__filter-checkbox-text:hover {
  opacity: 0.7;
  cursor: pointer;
}

.search-form__filter-checkbox-line {
  border-bottom: solid 1px rgba(170, 170, 170, 0.2);
  width: 100%;
  display: flex;
  padding: 44px 0 0 0;
}

@media screen and (max-width: 1023px) {
  .search-form__filter-checkbox-line {
    padding: 52px 0 0 0;
  }
}

@media screen and (max-width: 767px) {
  .filter-checkbox {
    margin-top: 30px;
  }
  .filter-checkbox__text {
    font-size: 11px;
  }
  .filter-checkbox__line {
    padding: 64px 0 0 0;
  }
} */
